// THIS FILE WAS AUTOGENERATED
module AI
{
"use strict";
    
    /**
     * Defines the level of severity for the event.
     */
    export enum SeverityLevel
    {
        Verbose = 0,
        Information = 1,
        Warning = 2,
        Error = 3,
        Critical = 4,
    }
}
